import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import contactReducer from './contactSlice';
import caseReducer from './caseSlice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        contact: contactReducer,
        case: caseReducer
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

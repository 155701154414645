import React from 'react';
import { RootState } from '@store/store';
import { useSelector } from 'react-redux';
import { CONTENT_ID } from '@constants/ids';
import { makeStyles, tokens, Spinner } from '@fluentui/react-components';
import { BrowserRouter, Routes, Route, useLocation, Navigate, Outlet } from 'react-router-dom';

import Header from '@components/Header';
import { ERoutes } from '@constants/routes';
import Breadcrumb from '@components/Breadcrumb';
const Home = React.lazy(() => import('@containers/Home'));
const SignIn = React.lazy(() => import('@containers/SignIn'));
const Landing = React.lazy(() => import('@containers/Landing'));
const CaseList = React.lazy(() => import('@containers/CaseList'));
const CaseDetail = React.lazy(() => import('@containers/CaseDetail'));
const CaseSuccess = React.lazy(() => import('@containers/CaseSuccess'));
const ContactSupport = React.lazy(() => import('@containers/ContactSupport'));

const useStyles = makeStyles({
  header: {
    flexShrink: 0,
    height: '60px',
  },
  breadcrumb: {
    flexShrink: 0,
    height: '40px',
    backgroundColor: tokens.colorNeutralBackground2,
    borderTop: `1px solid ${tokens.colorNeutralStroke2}`,
    borderBottom: `1px solid ${tokens.colorNeutralStroke2}`,
  },
  content: {
    flexGrow: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollBehavior: 'smooth',
    backgroundColor: tokens.colorNeutralBackground2,
  },
});

const LayoutAndGuardWrapper: React.FC = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { userId } = useSelector((state: RootState) => state.user);
  const showBreadcrumbPaths = [ERoutes.ContactSupport, ERoutes.CaseList, ERoutes.CaseDetail];
  const showBreadcrumb = showBreadcrumbPaths.some(path => new RegExp(`^${path}(/|$)`).test(pathname));
  return (userId ?
    <>
      <Header className={classes.header} />
      {showBreadcrumb && <Breadcrumb className={classes.breadcrumb} />}
      <div id={CONTENT_ID} className={classes.content}>
        <React.Suspense fallback={<Spinner size='extra-small' style={{ height: '100%' }} />}>
          <Outlet />
        </React.Suspense>
      </div>
    </> :
    <Navigate to={ERoutes.SignIn} />
  );
};

const UnsignedWrapper: React.FC = () => {
  const { userId } = useSelector((state: RootState) => state.user);
  return userId ? <Navigate to={ERoutes.Home} /> : (
    <React.Suspense fallback={<Spinner size='extra-small' style={{ height: '100%' }} />}>
      <Outlet />
    </React.Suspense>);
}

const Router: React.FC = () => (
  <Routes>
    <Route element={<UnsignedWrapper />}>
      <Route path={ERoutes.SignIn} element={<SignIn />} />
      <Route path={ERoutes.Pilot} element={<Landing />} />
    </Route>
    <Route element={<LayoutAndGuardWrapper />}>
      <Route path={ERoutes.Home} element={<Home />} />
      <Route path={ERoutes.CaseList} element={<CaseList />} />
      <Route path={ERoutes.ContactSupport} element={<ContactSupport />} />
      <Route path={`${ERoutes.CaseDetail}/:caseId`} element={<CaseDetail />} />
      <Route path={`${ERoutes.CaseSuccess}/:caseId`} element={<CaseSuccess />} />
      {/* Redirect to home page if the path is not matched */}
      <Route path={ERoutes.NotFound} element={<Navigate to="/" />} />
    </Route>
  </Routes>
);

// TODO: yren add error boundary here.
const RouterContainer: React.FC = () => {
  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
};

export default RouterContainer;

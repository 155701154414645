import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { MsalProvider } from '@azure/msal-react';
import { initializeIcons } from '@fluentui/react';
import { PublicClientApplication } from '@azure/msal-browser';
import { FluentProvider, webLightTheme } from '@fluentui/react-components'
import { msalConfig } from './authConfig'
import { Provider } from 'react-redux'
import { store } from '@store/store'
import { logger } from '@services/logger';
import { AppContext, IApp } from '@contexts/appContext';
import { v4 as uuidv4 } from 'uuid';
import App from './app.tsx'
import './index.css'
import './i18n.ts'

const appContextValue: IApp = {
  sessionId: uuidv4(),
};

logger.updateSessionId(appContextValue.sessionId);

window.onerror = (message, source, lineno, colno, error) => {
  const errorMessage = `window.onerror: ${message}, currentUrl: ${source}, lineNumber: ${lineno}, column: ${colno}, error: ${error}`;
  logger.error(errorMessage);
}

const initialize = async () => {
  initializeIcons();
  const msalInstance = new PublicClientApplication(msalConfig);
  await msalInstance.initialize();

  // Use Fluent Provider as the root component
  const fluentProviderStyle: React.CSSProperties = {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column'
  }

  createRoot(document.getElementById('root')!).render(
    <StrictMode>
      <FluentProvider theme={webLightTheme} style={fluentProviderStyle}>
        <Provider store={store}>
          <MsalProvider instance={msalInstance}>
            <AppContext.Provider value={appContextValue}>
              <App />
            </AppContext.Provider>
          </MsalProvider>
        </Provider>
      </FluentProvider>
    </StrictMode>,
  )
};

// For pilot CSP, redirect to the old CSP TODO: yren remove this after pilot
const hostname = window.location.hostname;
const urlParams = new URLSearchParams(window.location.search);
const isNewCSP = urlParams.get('isNewCSP');

if (hostname === 'support.ads.microsoft.com') {
  window.location.href = window.location.href.replace('support.ads.microsoft.com', 'vnext.support.ads.microsoft.com');
} else {
  logger.info('CSP Starts');
  if (isNewCSP === 'false') {
    logger.info('CSP redirects to Salesforces');
    window.location.href = 'https://help.xandr.com/s/';
  } else {
    logger.info('CSP stays in UCM CSP');
    initialize();
  }
}
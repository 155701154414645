import { OptionProps } from "@fluentui/react-components";
import { sspList, countryList, currencyList, timezoneList } from "./common";

export enum EStep {
  TopAndCat,
  CaseDetail,
  ContactDetail,
}

// These are the values for backend
export enum ETopic {
  AuditAntiMalvertising = 'Audit & Anti-Malvertising',
  ProductSupport = 'Product Support',
  BidderSSPDataIntegrationsSupport = 'Bidder, SSP & Data Integrations Support',
  ClientFinance = 'Client Finance',
  AccountServices = 'Account Services',
  ProgrammaticGuaranteedDeals = 'Programmatic Guaranteed Deals',
}

// These are the values for localization
export const topicLocalMap: Record<ETopic, string> = {
  [ETopic.AuditAntiMalvertising]: 'AuditAntiMalvertising',
  [ETopic.ProductSupport]: 'ProductSupport',
  [ETopic.BidderSSPDataIntegrationsSupport]: 'BidderSSPDataIntegrationsSupport',
  [ETopic.ClientFinance]: 'ClientFinance',
  [ETopic.AccountServices]: 'AccountServices',
  [ETopic.ProgrammaticGuaranteedDeals]: 'ProgrammaticGuaranteedDeals',
};

// These are the values for backend
export enum ECateogry {
  // Audit & Anti-Malvertising
  AppealACreativeRejection = 'Appeal a Creative Rejection',
  AppealADomainRejection = 'Appeal a Domain Rejection',
  RequestAuditOfANewCreative = 'Request Audit of a new Creative',
  RequestAuditOfANewDomain = 'Request Audit of a new Domain',
  UpdateCreativeBrandContentCategory = 'Update Creative Brand/Content Category',
  ComplaintAboutACreative = 'Complaint about a Creative',
  AntiMalvertisingRequests = 'Anti-Malvertising requests',
  OtherRequests = 'Other requests',

  // Product Support
  MicrosoftInvest = 'Microsoft Invest',
  CreativeInquiries = 'Creative Inquiries',
  MicrosoftMonetize = 'Microsoft Monetize',
  MicrosoftCurate = 'Microsoft Curate',
  YieldAnalytics = 'Yield Analytics (formerly Yieldex)',

  // Bidder, SSP & Data Integrations Support
  BidderSupportBidderAPI = 'Bidder Support & Bidder API',
  DataManagementTaxonomyUpdates = 'Data Management & Taxonomy Updates',
  ExternalSupply = 'External Supply',

  // Client Finance
  GeneralBilling = 'General Billing',
  FraudClaim = 'Fraud Claim',
  ResoldRevenueSellerPayment = 'Resold Revenue/Seller Payment',
  UpdateOfCustomerInformation = 'Update of Customer Information',
  CreditSetup = 'Credit Setup',
  Collections = 'Collections (Owed to Microsoft Advertising)',
  FinanceAccessControlRequest = 'Finance Access Control Request',
  CreditCardPayment = 'Credit Card Payment',
  NeedPrepaymentInvoice = 'Need Prepayment Invoice',
  YieldAnalyticsRelatedFinanceRequest = 'Yield Analytics related Finance Request',

  // Account Services
  GoogleAdManagerSeatRequest = 'Google Ad Manager Seat Request',
  FeatureEnablement = 'Feature Enablement',
  ObjectLimitIncrease = 'Object Limit Increase',
  ClientRequestedTerminations = 'Client Requested Terminations',
  CreditDebitRequests = 'Credit & Debit Requests',

  // Programmatic Guaranteed Deals
  GAMAdXDeals = 'GAM/AdX Deals',
  NewPGDealRequest = 'New PG Deal Request',
  EditExistingPGDeal = 'Edit Existing PG Deal',
}

// These are the values for localization
export const categoryLocalMap: Record<ECateogry, string> = {
  // Audit & Anti-Malvertising
  [ECateogry.AppealACreativeRejection]: 'AppealACreativeRejection',
  [ECateogry.AppealADomainRejection]: 'AppealADomainRejection',
  [ECateogry.RequestAuditOfANewCreative]: 'RequestAuditOfANewCreative',
  [ECateogry.RequestAuditOfANewDomain]: 'RequestAuditOfANewDomain',
  [ECateogry.UpdateCreativeBrandContentCategory]: 'UpdateCreativeBrandContentCategory',
  [ECateogry.ComplaintAboutACreative]: 'ComplaintAboutACreative',
  [ECateogry.AntiMalvertisingRequests]: 'AntiMalvertisingRequests',
  [ECateogry.OtherRequests]: 'OtherRequests',

  // Product Support
  [ECateogry.MicrosoftInvest]: 'MicrosoftInvest',
  [ECateogry.CreativeInquiries]: 'CreativeInquiries',
  [ECateogry.MicrosoftMonetize]: 'MicrosoftMonetize',
  [ECateogry.MicrosoftCurate]: 'MicrosoftCurate',
  [ECateogry.YieldAnalytics]: 'YieldAnalytics',

  // Bidder, SSP & Data Integrations Support
  [ECateogry.BidderSupportBidderAPI]: 'BidderSupportBidderAPI',
  [ECateogry.DataManagementTaxonomyUpdates]: 'DataManagementTaxonomyUpdates',
  [ECateogry.ExternalSupply]: 'ExternalSupply',

  // Client Finance
  [ECateogry.GeneralBilling]: 'GeneralBilling',
  [ECateogry.FraudClaim]: 'FraudClaim',
  [ECateogry.ResoldRevenueSellerPayment]: 'ResoldRevenueSellerPayment',
  [ECateogry.UpdateOfCustomerInformation]: 'UpdateOfCustomerInformation',
  [ECateogry.CreditSetup]: 'CreditSetup',
  [ECateogry.Collections]: 'Collections',
  [ECateogry.FinanceAccessControlRequest]: 'FinanceAccessControlRequest',
  [ECateogry.CreditCardPayment]: 'CreditCardPayment',
  [ECateogry.NeedPrepaymentInvoice]: 'NeedPrepaymentInvoice',
  [ECateogry.YieldAnalyticsRelatedFinanceRequest]: 'YieldAnalyticsRelatedFinanceRequest',

  // Account Services
  [ECateogry.GoogleAdManagerSeatRequest]: 'GoogleAdManagerSeatRequest',
  [ECateogry.FeatureEnablement]: 'FeatureEnablement',
  [ECateogry.ObjectLimitIncrease]: 'ObjectLimitIncrease',
  [ECateogry.ClientRequestedTerminations]: 'ClientRequestedTerminations',
  [ECateogry.CreditDebitRequests]: 'CreditDebitRequests',

  // Programmatic Guaranteed Deals
  [ECateogry.GAMAdXDeals]: 'GAMAdXDeals',
  [ECateogry.NewPGDealRequest]: 'NewPGDealRequest',
  [ECateogry.EditExistingPGDeal]: 'EditExistingPGDeal',
}

export interface IFormConfig {
  title: string;
  type: EFieldType;
  required: boolean | Array<ECateogry | string>;
  disabled?: boolean;
  options?: OptionProps[];
  showCategory?: Array<ECateogry | string>;
}

export enum EDetailFormKey {
  subject = 'subject',
  ssp = 'ssp',
  dealName = 'dealName',
  dealId = 'dealId',
  dealDateStart = 'dealStartDate',
  dealDateEnd = 'dealEndDate',
  timezone = 'timezone',
  cpm = 'cpm',
  impressionCount = 'impressionCount',
  currency = 'currency',
  buyerMemberId = 'buyerMemberId',
  description = 'description',
  domain = 'domain',
  appealJustification = 'appealJustification',
  dealProposalName = 'dealProposalName',
  dealProposalId = 'dealProposalId',
  severity = 'severity',
  relatedMembers = 'relatedMembers',
  attachments = 'attachments'
}

export enum EFieldType {
  text = 'text',
  file = 'file',
  date = 'date',
  select = 'select',
  multiText = 'multiText',
  multiSelect = 'multiSelect',
  severitySelect = 'severitySelect',
}

export enum ESeverity {
  level0 = '0',
  level1 = '1',
  level2 = '2',
  level3 = '3',
}

export const severityLocalMap: Record<ESeverity, string> = {
  [ESeverity.level0]: 'SeverityLevel0',
  [ESeverity.level1]: 'SeverityLevel1',
  [ESeverity.level2]: 'SeverityLevel2',
  [ESeverity.level3]: 'SeverityLevel3',
};

export const CreativeRejectionCat = [ECateogry.AppealACreativeRejection];
export const DomainRejectionCat = [ECateogry.AppealADomainRejection];
export const GAMDealsCat = [ECateogry.GAMAdXDeals];
export const PGDealCat = [ECateogry.NewPGDealRequest, ECateogry.EditExistingPGDeal];

// TODO: yren no search in Select. Hard to find.
const sspOptions = [{ value: '', text: 'Select' }, ...sspList.map((ssp) => ({ value: ssp, text: ssp }))];
const currencyOptions = [{ value: '', text: 'Select' }, ...currencyList.map((currency) => ({ value: currency, text: currency }))];
const countryOptions = [{ value: '', text: 'Select' }, ...countryList];
const timezoneOptions = [{ value: '', text: 'Select' }, ...timezoneList];
const severityOptions = Object.entries(ESeverity).map(([, value]) => ({ value, text: severityLocalMap[value] }));

// 'title' and 'text' in the options are mapped to the translation keys
export const DETAIL_FORM_CONFIG: Record<EDetailFormKey, IFormConfig> = {
  [EDetailFormKey.subject]: {
    type: EFieldType.text,
    title: 'Subject',
    required: true,
  },
  [EDetailFormKey.ssp]: {
    type: EFieldType.select,
    title: 'SSP',
    required: true,
    options: sspOptions,
    showCategory: PGDealCat,
  },
  [EDetailFormKey.dealName]: {
    type: EFieldType.text,
    title: 'DealName',
    required: true,
    showCategory: PGDealCat,
  },
  [EDetailFormKey.dealId]: {
    type: EFieldType.text,
    title: 'DealId',
    required: true,
    showCategory: PGDealCat,
  },
  [EDetailFormKey.dealDateStart]: {
    type: EFieldType.date,
    title: 'DealDateStart',
    required: [ECateogry.NewPGDealRequest],
    showCategory: PGDealCat,
  },
  [EDetailFormKey.dealDateEnd]: {
    type: EFieldType.date,
    title: 'DealDateEnd',
    required: [ECateogry.NewPGDealRequest],
    showCategory: PGDealCat,
  },
  [EDetailFormKey.timezone]: {
    type: EFieldType.select,
    title: 'Timezone',
    required: [ECateogry.NewPGDealRequest],
    options: timezoneOptions,
    showCategory: PGDealCat,
  },
  [EDetailFormKey.cpm]: {
    type: EFieldType.text,
    title: 'CPM',
    required: [ECateogry.NewPGDealRequest],
    showCategory: PGDealCat,
  },
  [EDetailFormKey.impressionCount]: {
    type: EFieldType.text,
    title: 'ImpressionCount',
    required: [ECateogry.NewPGDealRequest],
    showCategory: PGDealCat,
  },
  [EDetailFormKey.currency]: {
    type: EFieldType.select,
    title: 'Currency',
    required: false,
    options: currencyOptions,
    showCategory: [ECateogry.EditExistingPGDeal],
  },
  [EDetailFormKey.buyerMemberId]: {
    type: EFieldType.text,
    title: 'BuyerMemberId',
    required: true,
    showCategory: [ECateogry.NewPGDealRequest],
  },
  [EDetailFormKey.description]: {
    type: EFieldType.multiText,
    title: 'Description',
    required: true,
  },
  [EDetailFormKey.domain]: {
    type: EFieldType.text,
    title: 'Domain',
    required: true,
    showCategory: DomainRejectionCat,
  },
  [EDetailFormKey.appealJustification]: {
    type: EFieldType.text,
    title: 'AppealJustification',
    required: true,
    showCategory: [...CreativeRejectionCat, ...DomainRejectionCat],
  },
  [EDetailFormKey.dealProposalName]: {
    type: EFieldType.text,
    title: 'DealProposalName',
    required: true,
    showCategory: GAMDealsCat,
  },
  [EDetailFormKey.dealProposalId]: {
    type: EFieldType.text,
    title: 'DealProposalId',
    required: true,
    showCategory: GAMDealsCat,
  },
  [EDetailFormKey.severity]: {
    type: EFieldType.select,
    title: 'Severity',
    required: true,
    options: severityOptions,
  },
  [EDetailFormKey.relatedMembers]: {
    type: EFieldType.multiSelect,
    title: 'AddRelatedMembers',
    required: false,
  },
  [EDetailFormKey.attachments]: {
    type: EFieldType.file,
    title: 'Attachments',
    required: false,
  },
};

export enum EContactFormKey {
  contactName = 'contactName',
  contactEmail = 'contactEmail',
  companyName = 'companyName',
  ccEmails = 'ccEmails',
  submissionCountry = 'submissionCountry',
}

export const CONTACT_FORM_CONFIG: Record<EContactFormKey, IFormConfig> = {
  [EContactFormKey.contactName]: {
    type: EFieldType.text,
    title: 'ContactName',
    required: true,
    disabled: true,
  },
  [EContactFormKey.contactEmail]: {
    type: EFieldType.text,
    title: 'ContactEmail',
    required: true,
    disabled: true,
  },
  [EContactFormKey.companyName]: {
    type: EFieldType.text,
    title: 'CompanyName',
    required: [...GAMDealsCat, ...PGDealCat],
  },
  [EContactFormKey.ccEmails]: {
    type: EFieldType.text,
    title: 'CCEmails',
    required: false,
  },
  [EContactFormKey.submissionCountry]: {
    type: EFieldType.select,
    title: 'SubmissionCountry',
    required: true,
    options: countryOptions,
    showCategory: Object.values(ECateogry).filter((cat) => ![...CreativeRejectionCat, ...DomainRejectionCat].includes(cat)),
  },
};

export type TContactInfo = {
  // Controller
  step: EStep;
  // TopAndCat
  topic: ETopic;
  category: ECateogry;
  // CaseDetail
  checkOutageCriteria: boolean;
  [EDetailFormKey.ssp]: string;
  [EDetailFormKey.cpm]: string;
  [EDetailFormKey.dealId]: string;
  [EDetailFormKey.domain]: string;
  [EDetailFormKey.subject]: string;
  [EDetailFormKey.dealName]: string;
  [EDetailFormKey.timezone]: string;
  [EDetailFormKey.currency]: string;
  [EDetailFormKey.severity]: ESeverity;
  [EDetailFormKey.attachments]: string[];
  [EDetailFormKey.description]: string;
  [EDetailFormKey.dealDateEnd]: string;
  [EDetailFormKey.dealDateStart]: string;
  [EDetailFormKey.buyerMemberId]: string;
  [EDetailFormKey.relatedMembers]: string[];
  [EDetailFormKey.dealProposalId]: string;
  [EDetailFormKey.impressionCount]: string;
  [EDetailFormKey.dealProposalName]: string;
  [EDetailFormKey.appealJustification]: string;
  // ContactDetail
  [EContactFormKey.ccEmails]: string;
  [EContactFormKey.contactName]: string;
  [EContactFormKey.companyName]: string;
  [EContactFormKey.contactEmail]: string;
  [EContactFormKey.submissionCountry]: string;
}


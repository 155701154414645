export enum LoggerConstantsNumber {
    DefaultUserID = -1,
    Retries = 6,
    LogBufferSize = 100,
    LogBufferTimeOut = 60000,
}

// TODO: variables should be injected by server
// Keep the same defintion as those in UCM.
export enum LoggerEventNumber {
    FailureEvent = 3,
    InfoEvent = 1,
}
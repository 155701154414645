import { createSlice } from '@reduxjs/toolkit';

interface ICaseInfo {
    ticketId: string;
}

const initialState: ICaseInfo = {
    ticketId: '',
};

const caseSlice = createSlice({
    name: 'case',
    initialState,
    reducers: {
        resetCaseInfo: () => initialState,
        setCaseInfo: (state: ICaseInfo, action: { payload: ICaseInfo }) => {
            state.ticketId = action.payload.ticketId;
        },
    },
});

export const { setCaseInfo, resetCaseInfo } = caseSlice.actions;

export default caseSlice.reducer;

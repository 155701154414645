import { makeStyles, typographyStyles, tokens } from "@fluentui/react-components";

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    padding: '0 6px',
    alignItems: 'center',
    flexDirection: 'row',
  },
  logo: {
    width: '24px',
    height: '24px',
  },
  logoFont: {
    ...typographyStyles.body1,
    fontWeight: tokens.fontWeightSemibold,
    paddingLeft: '8px',
    cursor: 'pointer',
  },
});
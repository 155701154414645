import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { API_BASE_URL } from '@constants/env';

const apiClient = axios.create({
  withCredentials: true,
  baseURL: API_BASE_URL,
  timeout: 10000,
  headers: {
      post: {
          'Content-Type': 'application/json',
      }
  }
});

const handleError = (error: AxiosError) => {
  return Promise.reject(error);
};

const retryRequest = async (config: AxiosRequestConfig, retries: number = 3) => {
  for (let i = 0; i < retries; i++) {
    try {
      return await apiClient(config);
    } catch (error) {
      if (i === retries - 1) {
        throw error;
      }
    }
  }
};

apiClient.interceptors.request.use(
  (config) => config,
  handleError
);

apiClient.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  async (error: AxiosError) => {
    await handleError(error);
    
    // Check if the error response is Unauthorized (HTTP 401)
    if (error.response?.status === 401) {
      console.error('Unauthorized access - 401.');
      return Promise.reject(new Error('Unauthorized access.'));
    }

    if (error.config) {
      return retryRequest(error.config);
    }

    return Promise.reject(error);
  }
);

const fetchData = (url: string, config: AxiosRequestConfig = {}): Promise<unknown> => {
  return apiClient.get(url, config);
};

const postData = (url: string, data: unknown, config: AxiosRequestConfig = {}): Promise<unknown> => {
  return apiClient.post(url, data, config);
};

export { fetchData, postData };

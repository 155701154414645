import { postData } from "./httpClient"

interface IFetchUserInfoResquest {
  AADToken: string;
  UserName: string;
  Email: string;
}

export const fetchUserInfo = async (params: IFetchUserInfoResquest) => {
  try {
    const response = await postData('/api/v2/UserToken/FetchXandrToken', { ...params });
    return response;
  } catch (error) {
    throw new Error(`fetchUserInfo error: ${error}`);
  }
};
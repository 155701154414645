export const IS_PROD = import.meta.env.VITE_APP_ENV === 'production';

export const API_BASE_URL = IS_PROD ? 'https://ucm.ads.microsoft.com' : 'https://vnext-private-14.ucm.ads.microsoft.com';

// export const AUTH_CLIENT_ID = IS_PROD ? '3859ade8-b16f-454b-84fd-891a27a33974' : 'e0f69afb-8ede-4212-bd9a-3bffd0226230';
// export const AUTH_POLICY = IS_PROD ? 'https://xandraaimprd.b2clogin.com/xandraaimprd.onmicrosoft.com/B2C_1A_MIGRATION_SIGNIN_PRODUCTION' : 'https://xandraaimstg.b2clogin.com/xandraaimstg.onmicrosoft.com/B2C_1A_MIGRATION_SIGNIN_STAGING';
// export const AUTH_REDIRECT_URI = IS_PROD ? 'https://support.ads.microsoft.com' : 'https://vnext.support.ads.microsoft.com';
// export const AUTH_KNOWN_AUTHORITY = IS_PROD ? 'xandraaimprd.b2clogin.com' : 'xandraaimstg.b2clogin.com';

// TODO: yren use prod, remove after pilot
export const AUTH_CLIENT_ID = '3859ade8-b16f-454b-84fd-891a27a33974';
export const AUTH_POLICY = 'https://xandraaimprd.b2clogin.com/xandraaimprd.onmicrosoft.com/B2C_1A_MIGRATION_SIGNIN_PRODUCTION';
export const AUTH_REDIRECT_URI = 'https://support.ads.microsoft.com';
export const AUTH_KNOWN_AUTHORITY = 'xandraaimprd.b2clogin.com';